import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'image-swiper',
  label: {
    title: i18n.t('picture_carousel'),
    icon: require('@/assets/icon-component-label_ImageAdv.png'),
    limit: 5
  },
  sort: 4,
  data: {
    // 图片列表
    imageList: [],
    // 轮播高度
    swiper_height: 500,
    // 显示轮播分页器
    show_pagination: true,
    // 指示器颜色
    pagination_color: 'rgba(0,0,0,0.8)',
    // 激活的指示器颜色
    pagination_active_color: '#ffffff',
    // 自动播放
    autoplay: true,
    // 轮播方向
    direction: 'horizontal',
    // 图片填充
    image_fill: 'cover',
    // 切换效果
    effect: 'fade'
  },
  settings: {
    imageList: {
      type: SettingTypes.custom,
      component_name: 'ImageSwiperSelector'
    },
    swiper_height: {
      type: SettingTypes.slider,
      label: i18n.t('carousel_height'),
      props: {
        min: 20,
        max: 1210
      }
    },
    show_pagination: {
      type: SettingTypes.boolean,
      label: i18n.t('show_pager')
    },
    pagination_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('indicator_color'),
      default: 'rgba(0,0,0,0.8)'
    },
    pagination_active_color: {
      type: SettingTypes.color_picker,
      label: `${i18n.t('indicator_color_active')}`,
      default: '#ffffff'
    },
    autoplay: {
      type: SettingTypes.boolean,
      label: i18n.t('automatic_switching')
    },
    direction: {
      type: SettingTypes.radio_button,
      label: i18n.t('carousel_direction'),
      options: [
        { label: i18n.t('level'), value: 'horizontal' },
        { label: i18n.t('vertical'), value: 'vertical' }
      ]
    },
    image_fill: {
      type: SettingTypes.radio_button,
      label: i18n.t('picture_fill'),
      options: [
        { label: i18n.t('fill'), value: 'cover', icon: 'rb-icon-img-cover' },
        { label: i18n.t('peripheral_blank'), value: 'contain', icon: 'rb-icon-img-contain' }
      ]
    },
    effect: {
      type: SettingTypes.radio_button,
      label: i18n.t('switching_effect'),
      options: [
        { label: i18n.t('faded'), value: 'fade' },
        { label: i18n.t('slide'), value: 'slide' },
        { label: i18n.t('cube'), value: 'cube' },
        { label: i18n.t('cover'), value: 'coverflow' }
      ]
    }
  }
}
