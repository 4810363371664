import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'notice-bar',
  label: {
    title: i18n.t('announcement'),
    icon: require('@/assets/icon-component-label_Notice.png'),
    limit: 20
  },
  sort: 11,
  data: {
    // 公告内容
    content: '',
    // 公告背景颜色
    background_color: '#fff8e9',
    // 公告内容颜色
    content_color: '#646566',
    // 是否可关闭
    closeable: false
  },
  settings: {
    content: {
      type: SettingTypes.input,
      label: i18n.t('announcement'),
      props: {
        placeholder: i18n.t('please_fill_in_the_announcement')
      }
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: '#fff8e9'
    },
    content_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('text_color_a'),
      default: '#646566'
    },
    closeable: {
      type: SettingTypes.boolean,
      label: i18n.t('can_be_closed')
    }
  }
}
