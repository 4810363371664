<template>
  <notice-bar :mode="$$FormData.closeable ? 'closeable' : ''" left-icon="volume" :text="$$FormData.content || default_notice" :background="$$FormData.background_color" :color="$$FormData.content_color" />
</template>

<script>
import i18n from '@/lang/index'
import NoticeBar from 'vant/lib/notice-bar'
import 'vant/lib/notice-bar/style'

export default {
  name: 'NoticeBarPreview',
  components: { NoticeBar },
  data() {
    return {
      default_notice: i18n.t('please_fill_in_the_content__if_it_is_too_long__it_will_be_scrolled_on_the_mobile_phone')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
