import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'rich-text',
  label: {
    title: i18n.t('rich_text'),
    icon: require('@/assets/icon-component-label_RichText.png'),
    limit: 300
  },
  sort: 5,
  data: {
    // 背景颜色
    background_color: '#f9f9f9',
    // 是否全屏显示
    full_screen: false,
    // 富文本HTML
    content: ''
  },
  settings: {
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: '#f9f9f9'
    },
    full_screen: {
      type: SettingTypes.boolean,
      label: i18n.t('full_screen_display'),
      props: {
        activeText: i18n.t('full_screen_display'),
        inactiveText: i18n.t('not_full_screen_display')
      }
    },
    content: {
      type: SettingTypes.custom,
      component_name: 'RichTextEditor'
    }
  }
}
