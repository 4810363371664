<template>
  <div class="title-slicer-bar-icon">
    <div class="item-setting">
      <div class="header-setting">
        <div class="header-setting__label">{{ $t('split_icon') }}</div>
      </div>
      <div class="body-setting">
        <div class="body-setting__tip">{{ $t('recommended_picture_aspect_ratio_5') }}:{{ $t('2__or_use_the_default_image') }}</div>
        <image-selector v-model="$$FormData.slicer_icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleSlicerBarIcon'
}
</script>

<style lang="scss" scoped>

</style>
