<template>
  <div class="image-text-nav-selector">
    <div class="item-setting">
      <div class="header-setting">
        <div class="header-setting__tip">{{ $t('add_up_to_10_navigations__drag_selected_ones_to_sort_them') }}</div>
      </div>
      <div class="body-setting">
        <image-link-editor v-model="$$FormData.navList" :show-image="$$FormData.nav_type === 'image-text'" :limit="10" :add-text="$$FormData.nav_type === 'image-text' ? $t('add_graphic_navigation') : $t('add_text_navigation')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageTextNavSelector'
}
</script>

<style lang="scss" scoped>

</style>
