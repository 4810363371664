<template>
  <div class="video-module-setting">
    <div class="item-setting">
      <div class="header-setting">
        <div class="header-setting__label">{{ $t('video') }}</div>
        <div class="header-setting__value">
          <el-radio-group v-model="video_url_type">
            <el-radio :label="1">{{ $t('select_video') }}</el-radio>
            <el-radio :label="2">{{ $t('paste_video_address') }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-show="video_url_type === 1" class="body-setting">
        <div class="body-setting__tip">{{ $t('recommended_video_aspect_ratio_16') }}:9<br>
          <p style="color: red;">{{ $t('special_tips') }}：{{ $t('btuvcidnatnetlwbvhawcsocpuiwc！') }}</p>
          <p style="color: red;">{{ $t('or_choose_not_to_turn_on') }}【{{ $t('display_at_non-h5_end') }}】</p>
        </div>
        <video-selector v-model="$$FormData.video" />
      </div>
      <div v-show="video_url_type === 2" class="body-setting">
        <div class="body-setting__tip">{{ $t('the_applet_v2_15_and_above_supports_video_source_addresses_that_only_support__mp4_format') }}</div>
        <el-input v-model="$$FormData.video.src" :placeholder="$t('paste_the_video_playback_address_here')" />
      </div>
    </div>
    <div class="item-setting">
      <div class="header-setting">
        <div class="header-setting__label">{{ $t('cover_chart') }}</div>
        <div class="header-setting__value">
          <el-radio-group v-model="$$FormData.cover_type">
            <el-radio label="original" :disabled="video_url_type === 2">{{ $t('original_video_cover') }}</el-radio>
            <el-radio label="custom">{{ $t('custom_cover_page') }}</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-show="$$FormData.cover_type === 'custom'" class="body-setting">
        <div class="body-setting__tip">{{ $t('recommended_picture_aspect_ratio_16') }}:9</div>
        <image-selector v-model="$$FormData.video_cover" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoModuleSetting',
  data() {
    return {
      video_url_type: 1
    }
  },
  watch: {
    video_url_type(newVal) {
      if (newVal === 1) return
      this.$$FormData.cover_type = 'custom'
    }
  }
}
</script>

<style lang="scss" scoped>
.video-module-setting {}
</style>
