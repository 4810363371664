import i18n from '@/lang/index'
export default {
  name: 'image-sudoku',
  label: {
    title: i18n.t('picture_palace'),
    icon: require('@/assets/icon-component-label_ImageSudoku.svg'),
    limit: -1
  },
  sort: 6,
  data: {
    // 列列表
    columnList: []
  }
}
