<template>
  <div class="swiper-pc-selector">
    <div class="title-selector">
      <div class="title">{{ $t('add_picture') }}</div>
      <p class="tips">{{ $t('if_you_want_to_be_the_top_carousel__choose_a_picture_of_1210x_500_or_the_same_scale。') }}</p>
    </div>
    <div class="body-selector">
      <image-link-editor v-model="$$FormData.imageList" :limit="100" :title-placeholder="$t('it_is_suggested_that_within_10_words__do_not_fill_in')" :show-title="false" :add-text="$t('add_picture')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSwiperSelector'
}
</script>

<style lang="scss" scoped>
.swiper-pc-selector {
  .title-selector {
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: #ffffff;
    .title {
      color: #323233;
      font-size: 14px;
    }
    .tips {
      color: #969799;
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  .body-selector {
    padding: 12px 16px;
    background-color: #f7f8fa;
    overflow: hidden;
  }
}
</style>
