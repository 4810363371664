import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'image-adv',
  label: {
    title: i18n.t('picture_advertising'),
    icon: require('@/assets/icon-component-label_ImageAdv.png'),
    limit: 300
  },
  sort: 3,
  data: {
    // 图片模板
    image_tpl: 'single',
    // 图片列表
    imageList: [],
    // 图片样式
    image_style: 'normal',
    // 图片倒角
    image_chamfer: 'straight',
    // 页面边距
    page_edge: 0,
    // 图片间距
    image_space: 0,
    // 轮播海报高度
    swiper_height: 260
  },
  settings: {
    image_tpl: {
      type: SettingTypes.radio_button,
      label: i18n.t('select_template'),
      column: true,
      options: [
        { label: i18n.t('one_row_by_one'), value: 'single', icon: 'rb-icon-up2end' },
        { label: i18n.t('carousel_poster'), value: 'swiper', icon: 'rb-icon-carousel' },
        { label: i18n.t('large_image_horizontal_slide'), value: 'big-slide', icon: 'rb-icon-big-slide' },
        { label: i18n.t('figure_slide_horizontal'), value: 'small-slide', icon: 'rb-icon-small-slide' },
        { label: i18n.t('navigation_lateral_slide'), value: 'nav-slide', icon: 'rb-icon-nav-slide' },
        { label: i18n.t('draw_hot_zone'), value: 'hotarea', icon: 'rb-icon-hotarea' },
      ],
      space_line: true
    },
    image_list: {
      type: SettingTypes.custom,
      component_name: 'ImageAdvSelector',
      space_line: true
    },
    image_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('picture_style'),
      options: [
        { label: i18n.t('general'), value: 'normal', icon: 'rb-icon-image-ad-normal' },
        { label: i18n.t('projection'), value: 'shadow', icon: 'rb-icon-shadow' }
      ]
    },
    image_chamfer: {
      type: SettingTypes.radio_button,
      label: i18n.t('picture_chamfer'),
      options: [
        { label: i18n.t('right_angle'), value: 'straight', icon: 'rb-icon-corner-straight' },
        { label: i18n.t('fillet'), value: 'round', icon: 'rb-icon-corner-round' }
      ]
    },
    page_edge: {
      type: SettingTypes.slider,
      label: i18n.t('page_margins'),
      props: {
        min: 0,
        max: 60
      }
    },
    image_space: {
      type: SettingTypes.slider,
      label: i18n.t('picture_spacing'),
      show: (data) => data.image_tpl !== 'swiper',
      props: {
        min: 0,
        max: 60
      }
    },
    swiper_height: {
      type: SettingTypes.slider,
      label: i18n.t('carousel_height'),
      show: data => data.image_tpl === 'swiper',
      props: {
        min: 0,
        max: 750
      }
    }
  }
}
