import i18n from '@/lang/index'
export default {
  name: 'seckill',
  label: {
    title: i18n.t('seckill_module'),
    icon: require('@/assets/icon-component-label_Seckill.svg'),
    limit: 1
  },
  decor_type: 'admin',
  sort: 998
}
