import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'magic-cube',
  label: {
    title: i18n.t('rubik_s_cube'),
    icon: require('@/assets/icon-component-label_MagicCube.png'),
    limit: 200
  },
  sort: 6,
  data: {
    // 魔方密度
    block_density: '2x1',
    // 魔方块列表
    blockList: [],
    // 魔方样式
    magic_style: 'row-two',
    // 图片间隙
    image_space: 0,
    // 页面间距
    page_edge: 0
  },
  settings: {
    block_density: {
      type: SettingTypes.select,
      label: i18n.t('rubik_s_cube_density'),
      show: data => data.magic_style === 'custom',
      options: [
        { label: '4x4', value: '4x4' },
        { label: '5x5', value: '5x5' },
        { label: '6x6', value: '6x6' },
        { label: '7x7', value: '7x7' }
      ]
    },
    blockList: {
      type: SettingTypes.custom,
      component_name: 'MagicCubeBlocks'
    },
    magic_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('rubik_s_cube_style'),
      column: true,
      options: [
        { label: i18n.t('two_in_a_row'), value: 'row-two', icon: 'rb-icon-cuberow' },
        { label: i18n.t('a_row_of_three'), value: 'row-three', icon: 'rb-icon-cuberow2' },
        { label: i18n.t('a_row_of_four'), value: 'row-four', icon: 'rb-icon-cuberow1' },
        { label: i18n.t('left_two_right_two'), value: 'left-right-two', icon: 'rb-icon-cube' },
        { label: i18n.t('left_one_right_two'), value: 'left-one-right-two', icon: 'rb-icon-cubeto' },
        { label: i18n.t('on_the_second'), value: 'top-one-bottom-two', icon: 'rb-icon-cube-upto' },
        { label: i18n.t('left_one_right_three'), value: 'left-one-right-three', icon: 'rb-icon-cubeto1' },
        { label: i18n.t('custom'), value: 'custom', disabled: true, tooltip: i18n.t('custom_style_in_development'), icon: 'rb-icon-cube-custom' }
      ]
    },
    image_space: {
      type: SettingTypes.slider,
      label: i18n.t('picture_gap'),
      props: {
        min: 0,
        max: 60
      }
    },
    page_edge: {
      type: SettingTypes.slider,
      label: i18n.t('page_spacing'),
      props: {
        min: 0,
        max: 60
      }
    }
  }
}
