<template>
  <div class="shop-info-background-image">
    <div class="shop-info-background-image__title">{{ $t('background_image') }}</div>
    <div class="shop-info-background-image__tips">{{ $t('suggested_dimensions') }}：{{ $t('7pwtsdnmtpwbcostftp') }}</div>
    <div class="shop-info-background-image__image">
      <image-selector v-model="$$FormData.background_image" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopInfoBackgroundImage'
}
</script>

<style lang="scss" scoped>
.shop-info-background-image {
  padding: 12px 16px;
  &__title {
    color: #323233;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
  }
  &__tips {
    margin-top: 10px;
    color: #969799;
    font-size: 12px;
    line-height: 18px;
  }
  &__image {
    margin-top: 10px;
  }
}
</style>
