import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

// 文字对齐方式
const positionOptions = [
  { label: i18n.t('left_display'), value: 'left', icon: 'rb-icon-align-left' },
  { label: i18n.t('center_display'), value: 'center', icon: 'rb-icon-align-center' }
]

export default {
  name: 'title-text',
  label: {
    title: i18n.t('title_text'),
    icon: require('@/assets/icon-component-label_TitleText.png'),
    limit: 50
  },
  sort: 1,
  data: {
    // 标题文字
    title: i18n.t('title'),
    // 标题字体大小
    title_font_size: 32,
    // 标题字体粗细
    title_font_weight: 'bold',
    // 标题颜色
    title_color: '#323233',
    // 描述文字
    desc: i18n.t('description_content'),
    // 描述字体大小
    desc_font_size: 24,
    // 描述字体粗细
    desc_font_weight: '400',
    // 描述颜色
    desc_color: '#969799',
    // 标题、描述对齐
    position: 'left',
    // 背景颜色
    background_color: '#ffffff',
    // 显示底部分割线
    show_bottom_slicer: false,
    // 显示更多
    show_more: false,
    // 查看更多样式【1、2、3】
    more_style: 1,
    // 查看更多文字
    more_text: i18n.t('view_more'),
    // 查看更多链接
    more_link: ''
  },
  settings: {
    title: {
      type: SettingTypes.input,
      label: i18n.t('title_content')
    },
    desc: {
      type: SettingTypes.input,
      label: i18n.t('description_content'),
      props: {
        type: 'textarea',
        placeholder: i18n.t('please_enter_a_description_of_the_content__up_to_100_words！'),
        maxlength: 100
      },
      space_line: true
    },
    position: {
      type: SettingTypes.radio_button,
      label: i18n.t('position_display'),
      options: positionOptions
    },
    title_font_size: {
      type: SettingTypes.radio_button,
      label: i18n.t('title_size'),
      options: [
        { label: `${i18n.t('big_16')}`, value: 32, icon: 'rb-icon-font-x' },
        { label: `${i18n.t('middle_14')}`, value: 28, icon: 'rb-icon-font-m' },
        { label: `${i18n.t('small_12')}`, value: 24, icon: 'rb-icon-font-s' }
      ]
    },
    desc_font_size: {
      type: SettingTypes.radio_button,
      label: i18n.t('description_size'),
      options: [
        { label: `${i18n.t('big_16')}`, value: 32, icon: 'rb-icon-font-x' },
        { label: `${i18n.t('middle_14')}`, value: 28, icon: 'rb-icon-font-m' },
        { label: `${i18n.t('small_12')}`, value: 24, icon: 'rb-icon-font-s' }
      ]
    },
    title_font_weight: {
      type: SettingTypes.radio_button,
      label: i18n.t('header_weight'),
      options: [
        { label: i18n.t('conventional_body'), value: '400', icon: 'rb-icon-font-regular' },
        { label: i18n.t('bold'), value: 'bold', icon: 'rb-icon-font-bold' }
      ]
    },
    desc_font_weight: {
      type: SettingTypes.radio_button,
      label: i18n.t('description_thickness'),
      options: [
        { label: i18n.t('conventional_body'), value: '400', icon: 'rb-icon-font-regular' },
        { label: i18n.t('bold'), value: 'bold', icon: 'rb-icon-font-bold' }
      ]
    },
    title_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('title_color'),
      default: '#323233'
    },
    desc_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('description_color'),
      default: '#969799'
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: '#ffffff'
    },
    show_bottom_slicer: {
      type: SettingTypes.boolean,
      label: i18n.t('bottom_split_line'),
      props: {
        activeText: i18n.t('display'),
        inactiveText: i18n.t('do_not_display')
      },
      space_line: true
    },
    show_more: {
      type: SettingTypes.boolean,
      label: i18n.t('view_more'),
      props: {
        activeText: i18n.t('display'),
        inactiveText: i18n.t('do_not_display')
      },
      option: {
        key: 'more_style',
        type: 'radio',
        options: [
          { label: i18n.t('style_one'), value: 1 },
          { label: i18n.t('style_two'), value: 2 },
          { label: i18n.t('style_three'), value: 3 }
        ],
        custom_component_name: 'TitleTextMoreStyle'
      }
    }
  }
}
