var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-pc"},[(_vm.show_swiper)?_c('div',{staticClass:"swiper-pc__swiper",style:({
      height: _vm.$$FormData.swiper_height + 'px',
      paddingLeft: _vm.$$FormData.page_edge + 'px',
      paddingRight: _vm.$$FormData.page_edge + 'px',
      '--dot-color': _vm.$$FormData.pagination_color,
      '--dot-color-active': _vm.$$FormData.pagination_active_color
    })},[(_vm.$$FormData.imageList.length)?_c('div',{directives:[{name:"swiper",rawName:"v-swiper",value:(_vm.swiperOptions),expression:"swiperOptions"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.$$FormData.imageList),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('a',{staticClass:"swiper-pc__item",style:({height: _vm.$$FormData.swiper_height + 'px'}),attrs:{"href":_vm.$$GetLinkValue(item.link),"target":"_blank"}},[_c('div',{staticClass:"swiper-pc__image",style:({
                backgroundImage: `url(${item.image.src})`,
                backgroundSize: _vm.$$FormData.image_fill
              })})])])}),0),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})]):_c('div',{staticClass:"swiper-pc__empty"},[_vm._v(_vm._s(_vm.$t('please_add_pictures')))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }