import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'page-params',
  label: {
    title: i18n.t('page_parameters'),
    icon: require('@/assets/icon-accessary_PageParams.png')
  },
  sort: 1,
  data: {
    // 页面名称、标题
    page_name: i18n.t('page_title'),
    // 页面描述
    page_desc: ''
  },
  settings: {
    page_name: {
      type: SettingTypes.input,
      label: i18n.t('page_name'),
      props: {
        placeholder: i18n.t('please_fill_in_the_page_title__up_to_50_characters！'),
        maxlength: 50
      }
    },
    page_desc: {
      type: SettingTypes.input,
      label: i18n.t('page_description'),
      props: {
        type: 'textarea',
        placeholder: i18n.t('fill_in_the_page_description_can_better_let_people_understand_your_page！'),
        maxlength: 200
      }
    }
  }
}
