import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'to-shop',
  label: {
    title: i18n.t('enter_the_shop'),
    icon: require('@/assets/icon-component-label_ToShop.png'),
    limit: 1
  },
  sort: 10,
  data: {
    // 店铺信息
    shopInfo: '',
    // 文案
    label: i18n.t('enter_the_shop')
  },
  settings: {
    shopInfo: {
      type: SettingTypes.custom,
      component_name: 'ToShopSelector'
    },
    label: {
      type: SettingTypes.input,
      label: i18n.t('copy_a'),
      props: {
        maxlength: 7
      }
    }
  }
}
