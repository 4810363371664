<template>
  <div class="image-adv-selector">
    <div class="title-selector">
      <div class="title">{{ $t('add_picture') }}</div>
      <p class="tips">{{ tplTip }}</p>
    </div>
    <div class="body-selector">
      <image-link-editor v-model="$$FormData.imageList" :limit="linkLimit" :type="$$FormData.image_tpl === 'hotarea' ? 'hotarea' : 'link'" :title-placeholder="$t('it_is_suggested_that_within_10_words__do_not_fill_in')" :add-text="$t('add_background_map')" />
    </div>
  </div>
</template>

<script>
import i18n from '@/lang/index'
export default {
  name: 'ImageAdvSelector',
  computed: {
    // 最大个数
    linkLimit() {
      if (this.$$FormData.image_tpl === 'nav-slide') return 15
      return 10
    },
    // 提示信息
    tplTip() {
      switch (this.$$FormData.image_tpl) {
        case 'single':
          return i18n.t('autadadtmtatooaatrwip')
        case 'swiper':
          return i18n.t('autadadtmtataoatrsip')
        case 'big-slide':
          return i18n.t('autadadtmtatooaatrwip_a')
        case 'small-slide':
          return i18n.t('autadadtmtatooaatrwip_b')
        case 'nav-slide':
          return i18n.t('autadadtmtatooaatrwip_c')
        case 'hotarea':
          return i18n.t('autadadtmtataoiirttwbpthbltpatsoaspblt')
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.image-adv-selector {
  .title-selector {
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: #ffffff;
    .title {
      color: #323233;
      font-size: 14px;
    }
    .tips {
      color: #969799;
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;
    }
  }
  .body-selector {
    padding: 12px 16px;
    background-color: #f7f8fa;
    overflow: hidden;
  }
}
</style>
