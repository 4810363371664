import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'
import { uuid } from '@/utils/foundation'

export default {
  name: 'image-text-nav',
  label: {
    title: i18n.t('graphic_navigation'),
    icon: require('@/assets/icon-component-label_ImageTextNavigator.png'),
    limit: 10
  },
  sort: 4,
  data: {
    // 导航列表
    navList: [i18n.t('1'), i18n.t('2'), i18n.t('3'), i18n.t('4')].map(item => ({
      __key: uuid(),
      image: '',
      title: `${i18n.t('navigation')}${item}`,
      link: ''
    })),
    // 导航类型【image-text：图文，text：文字】
    nav_type: 'image-text',
    // 导航样式
    nav_style: 'fixed',
    // 一屏显示数量
    nav_show_num: 4,
    // 背景颜色
    background_color: 'rgba(255,255,255,0)',
    // 文字颜色
    text_color: '#303133',
    // 页面边距
    page_edge: 30,
    // 图片缩放大小
    image_scale: 0.66,
    // 图片圆角
    image_radius: 30
  },
  settings: {
    navList: {
      type: SettingTypes.custom,
      component_name: 'ImageTextNavSelector'
    },
    nav_type: {
      type: SettingTypes.radio_button,
      label: i18n.t('navigation_type'),
      options: [
        { label: i18n.t('graphic_navigation'), value: 'image-text' },
        { label: i18n.t('text_navigation'), value: 'text' }
      ]
    },
    nav_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('navigation_style'),
      options: [
        { label: i18n.t('fixed'), value: 'fixed', icon: 'rb-icon-fixed' },
        { label: i18n.t('horizontal_sliding'), value: 'scroll', icon: 'rb-icon-scroll' }
      ]
    },
    page_edge: {
      type: SettingTypes.slider,
      label: i18n.t('page_margins'),
      props: {
        min: 0,
        max: 30
      }
    },
    image_scale: {
      type: SettingTypes.slider,
      label: i18n.t('picture_zoom'),
      props: {
        min: 0.3,
        max: 1,
        step: 0.01
      }
    },
    image_radius: {
      type: SettingTypes.slider,
      label: i18n.t('picture_fillet'),
      props: {
        min: 0,
        max: 750
      }
    },
    nav_show_num: {
      type: SettingTypes.select,
      label: i18n.t('one_screen_display'),
      show: data => data.nav_style === 'scroll',
      options: [...new Array(10)].map((item, index) => ({
        label: `${index + 1}个导航`,
        value: index + 1
      }))
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: 'rgba(255,255,255,0)'
    },
    text_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('text_color_a'),
      default: '#000000'
    }
  }
}
