<template>
  <div class="seckill">
    <p class="seckill__title">{{ $t('seckill_module') }}</p>
    <p class="seckill__subtitle">{{ $t('giipbtctmdnntbpacpap。') }}</p>
  </div>
</template>

<script>
export default {
  name: 'SeckillPreview'
}
</script>

<style lang="scss" scoped>
.seckill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 275px;
  margin: 0 auto;
  background-color: #fff;
  overflow: hidden;
  &__title {
    font-size: 24px;
  }
  &__subtitle {
    font-size: 16px;
    color: #999999;
    margin-top: 10px;
  }
}
</style>
