import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'
import { decorType } from '@/utils/decor-types'

const fixed_top_tip = `${decorType === 'seller' ? i18n.t('effective_only_on_store_homepage_or_micro_page') : i18n.t('effective_only_on_micro_pages')}`

export default {
  name: 'goods-search',
  label: {
    title: i18n.t('commodity_search'),
    icon: require('@/assets/icon-component-label_GoodsSearch.png'),
    limit: 2
  },
  sort: 8,
  data: {
    // 显示位置
    position: 'normal',
    // 展示模式
    show_style: 'resident',
    // 框体样式
    frame_style: 'square',
    // 文本位置
    text_position: 'left',
    // frame_height
    frame_height: 80,
    // 背景颜色
    background_color: '#f9f9f9',
    // 框体颜色
    frame_color: '#ffffff',
    // 文本颜色
    text_color: '#969799'
  },
  settings: {
    position: {
      type: SettingTypes.radio_button,
      label: i18n.t('display_position'),
      options: [
        { label: i18n.t('normal_mode'), value: 'normal', icon: 'rb-icon-search-normal' },
        { label: i18n.t('scroll_to_top_fixed'), value: 'fixed-top', icon: 'rb-icon-search-sticky', tooltip: fixed_top_tip }
      ]
    },
    show_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('presentation_mode'),
      show: (data) => data.position === 'fixed-top',
      options: [
        { label: i18n.t('resident_mode'), value: 'resident', icon: 'rb-icon-search-fixed' },
        { label: i18n.t('slide-up_disappears_and_slide-down_appears'), value: 'scroll-visible', icon: 'rb-icon-search-scroll', tooltip: fixed_top_tip }
      ]
    },
    frame_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('frame_style'),
      options: [
        { label: i18n.t('square'), value: 'square', icon: 'rb-icon-search-square' },
        { label: i18n.t('round'), value: 'round', icon: 'rb-icon-search-round' }
      ]
    },
    text_position: {
      type: SettingTypes.radio_button,
      label: i18n.t('text_position'),
      options: [
        { label: i18n.t('left'), value: 'left', icon: 'rb-icon-align-left' },
        { label: i18n.t('centered'), value: 'center', icon: 'rb-icon-align-center' }
      ]
    },
    frame_height: {
      type: SettingTypes.slider,
      label: i18n.t('frame_height'),
      props: {
        min: 56,
        max: 80
      }
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: '#f9f9f9'
    },
    frame_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('frame_color'),
      default: '#ffffff'
    },
    text_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('text_color'),
      default: '#969799'
    }
  }
}
