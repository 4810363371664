import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'shop-info',
  label: {
    title: i18n.t('store_information'),
    icon: require('@/assets/icon-component-label_ShopInfo.png'),
    limit: 50
  },
  sort: 9,
  data: {
    // 店铺信息
    shopInfo: '',
    // 背景图片
    background_image: '',
    // 店铺样式
    shop_style: 1
  },
  settings: {
    shopInfo: {
      type: SettingTypes.custom,
      component_name: 'ShopInfoSelector'
    },
    background_image: {
      type: SettingTypes.custom,
      component_name: 'ShopInfoBackgroundImage'
    },
    shop_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('display_style'),
      column: true,
      options: [i18n.t('1'), i18n.t('2'), i18n.t('3'), i18n.t('4'), i18n.t('5')].map((item, index) => ({
        label: `样式${item}`,
        value: index + 1,
        icon: `rb-icon-shop-info-${index + 1}`
      }))
    }
  }
}
