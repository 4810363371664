<template>
  <div>
    <div class="item-setting">
      <div class="header-setting">
        <div class="header-setting__label">{{ $t('picture_on_the_left') }}</div>
        <div class="header-setting__value">
        </div>
      </div>
      <div class="body-setting">
        <div class="body-setting__tip">{{ $t('please_choose_a_picture_of_190x_260_or_the_same_scale') }}</div>
        <image-link-editor v-model="headLink" :limit="1" :show-title="false" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsSliderImageSelector',
  computed: {
    headLink: {
      get() {
        const { headLink } = this.$$FormData
        return headLink ? [headLink] : []
      },
      set(newVal) {
        this.$$FormData.headLink = newVal[0]
      },
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
