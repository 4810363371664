var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-text",style:({backgroundColor: _vm.$$FormData.background_color})},[_c('div',{staticClass:"title-text__content",class:[
      `title-text__${_vm.$$FormData.position}`,
      {'title-text__bottom-slicer': _vm.$$FormData.show_bottom_slicer},
      `title-text__more-style${_vm.$$FormData.more_style}`
    ]},[(_vm.$$FormData.show_more)?_c('div',{staticClass:"title-text__more"},[(_vm.$$FormData.more_style !== 3)?_c('span',{staticClass:"title-text__more-text"},[_vm._v(_vm._s(_vm.$$FormData.more_text))]):_vm._e(),(_vm.$$FormData.more_style !== 1)?_c('i',{staticClass:"title-text__more-arrow ri-arrow-right-s-line"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"title-text__body"},[_c('h1',{staticClass:"title-text__title",style:({
          fontSize: (_vm.$$FormData.title_font_size ) * 0.5 + 'px',
          fontWeight: _vm.$$FormData.title_font_weight,
          color: _vm.$$FormData.title_color
        })},[_vm._v(_vm._s(_vm.$$FormData.title || ''))]),(_vm.$$FormData.desc)?_c('p',{staticClass:"title-text__desc",style:({
          fontSize: (_vm.$$FormData.desc_font_size ) * 0.5 + 'px',
          fontWeight: _vm.$$FormData.desc_font_weight || '400',
          color: _vm.$$FormData.desc_color
        })},[_vm._v(_vm._s(_vm.$$FormData.desc))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }