import i18n from '@/lang/index'
import SettingTypes from '@/utils/setting-types'

export default {
  name: 'assist-slicer',
  label: {
    title: i18n.t('auxiliary_segmentation'),
    icon: require('@/assets/icon-component-label_AssistSlicer.png'),
    limit: 200
  },
  sort: 7,
  data: {
    // 分割类型
    slicer_type: 'blank',
    // 空白高度
    blank_height: 60,
    // 分割线样式
    slicer_style: 'solid',
    // 左右边距
    slicer_padding: 0,
    // 分割线颜色
    slicer_color: '#e5e5e5',
    // 背景颜色
    background_color: 'rgba(255,255,255,0)'
  },
  settings: {
    slicer_type: {
      type: SettingTypes.radio_button,
      label: i18n.t('segmentation_type'),
      options: [
        { label: i18n.t('auxiliary_blank'), value: 'blank', icon: 'rb-icon-white' },
        { label: i18n.t('auxiliary_line'), value: 'line', icon: 'rb-icon-line' }
      ]
    },
    blank_height: {
      type: SettingTypes.slider,
      label: i18n.t('blank_height'),
      show: data => data.slicer_type === 'blank',
      props: {
        min: 0,
        max: 200
      }
    },
    slicer_style: {
      type: SettingTypes.radio_button,
      label: i18n.t('split_line_style'),
      show: data => data.slicer_type === 'line',
      options: [
        { label: i18n.t('solid_line'), value: 'solid', icon: 'rb-icon-line-solid' },
        { label: i18n.t('dashed_line'), value: 'dashed', icon: 'rb-icon-line-dashed' },
        { label: i18n.t('dot_line'), value: 'dotted', icon: 'rb-icon-line-dotted' }
      ]
    },
    slicer_padding: {
      type: SettingTypes.radio_button,
      label: i18n.t('left_and_right_margins'),
      show: data => data.slicer_type === 'line',
      options: [
        { label: i18n.t('bilateral'), value: 0, icon: 'rb-icon-line-no-padding' },
        { label: i18n.t('left_and_right_edges'), value: 30, icon: 'rb-icon-line-padding' }
      ]
    },
    slicer_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('auxiliary_line_color'),
      default: '#e5e5e5',
      show: data => data.slicer_type === 'line'
    },
    background_color: {
      type: SettingTypes.color_picker,
      label: i18n.t('background_color'),
      default: 'rgba(255,255,255,0)'
    }
  }
}
